<template>
    <div class="login-page">
      <div class="login-page__wrapper">
        <div class="login-page__img">
          <img src="img/logo.svg" class="logo_desktop" />
          <img src="img/logo_auth_mobile.svg" class="logo_mobile" />
        </div>
        <div class="login-page__content">
          <form class="login-form">
            <div class="login-form__text">
              <p>enter the code sent to your email</p>
            </div>
            <div class="login-form__content">
              <div class="login-form__input">
                <input
                  class="login-form__input-item login-form__input-forgot"
                  type="text"
                  placeholder=". . . . ."
                  v-model="code"
                >
              </div>
              <div class="login-form__submit">
                <input
                  class="btn btn-yellow login-form__submit-item"
                  type="button"
                  value="CONFIRM"
                  @click="confirm"
                >
              </div>
            </div>
          </form>
          <div class="resend-code login-page__resend">
            <p class="resend-code__time">{{ cooldown }}</p>
            <a class="resend-code__link clickable" @click="resendCode">resend code</a>
          </div><a class="btn btn-blue login-page__back clickable" @click="backToLogin">back</a>
          <a class="login-page__link" href="http://trident-guard.com">trident-guard.com</a>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'Activate',
  data() {
    return {
      username: this.$store.state.auth.email,
      password: this.$store.state.auth.password,
      code: '',
      cooldown: 120, // Счетчик
      cooldown_start: 120, // Значение с которого начинаем считать
    };
  },
  methods: {
    confirm() {
      if (this.$http?.defaults?.headers?.common?.Authorization) delete this.$http.defaults.headers.common.Authorization;

      this.$http.post(`${process.env.VUE_APP_PUBLIC_PREFIX}/registration/activate`, {
        paramName: 'password',
        email: this.username,
        code: this.code,
      })
        .then((response) => {
          switch (response.status) {
            case 200:
              this.$nextTick(() => {
                this.login();
              });
              break;
            case 205:
              this.$root.snackbar.warn('Wrong code, try again');
              break;
            default:
              this.$root.snackbar.warn(response.data);
          }
        })
        .catch((error) => {
          if (error.response) {
            switch (error.response.status) {
              default:
                this.$root.snackbar.warn('Error');
            }
            console.log(error.response?.data ? error.response.data : 'Error');
          } else if (error.request) {
            this.$root.snackbar.warn(error.request);
            console.log(error.request);
          } else {
            this.$root.snackbar.warn(error.message);
          }
        });
    },
    backToLogin() {
      this.$router.push({ path: '/login' });
    },
    login() {
      if (this.$http?.defaults?.headers?.common?.Authorization) delete this.$http.defaults.headers.common.Authorization;

      this.$http.post(`${process.env.VUE_APP_PUBLIC_PREFIX}/login`, {
        username: this.$store.state.auth.email,
        password: this.$store.state.auth.password,
      })
        .then((response) => {
          this.$root.snackbar.info('Login success!');
          this.$http.defaults.headers.common.Authorization = response.data.token;

          this.$store.dispatch('auth/setToken', response.data.token);
          this.$store.dispatch('auth/setEmail', this.username);
          this.$store.dispatch('auth/setPassword', '');
          this.$router.push({ path: '/' });
        })
        .catch((error) => {
          if (error.response) {
            switch (error.response.status) {
              case 401:
                this.$root.snackbar.warn('No such login');
                break;
              case 402:
                this.$root.snackbar.warn('Wrong password');
                break;
              case 404:
                this.$root.snackbar.warn('Wrong email or password');
                break;
              case 405:
                this.$root.snackbar.warn('Entry time limit exceeded');
                break;
              default:
                this.$root.snackbar.warn('Wrong email or password!');
            }
            console.log(error.response?.data ? error.response.data : 'Wrong email or password!');
          } else if (error.request) {
            this.$root.snackbar.warn(error.request);
            console.log(error.request);
          } else {
            this.$root.snackbar.warn(error.message);
          }
        });
    },
    cooldownTicker() {
      this.cooldown = this.cooldown_start;
      const ticker = setInterval(() => {
        this.cooldown -= 1;
        if (this.cooldown <= 0) {
          clearInterval(ticker);
        }
      }, 1000);
    },
    resendCode() {
      if (this.cooldown > 0) {
        this.$root.snackbar.warn('Cooldown not over yet');
        return false;
      }

      if (this.$http?.defaults?.headers?.common?.Authorization) delete this.$http.defaults.headers.common.Authorization;

      this.$http.post(`${process.env.VUE_APP_PUBLIC_PREFIX}/registration`, {
        email: this.username,
        password: this.password,
        language: process.env.VUE_APP_DEFAULT_LOCALE.toLocaleUpperCase(),
      })
        .then((response) => {
          switch (response.status) {
            case 200:
              this.$root.snackbar.warn('No created new account');
              break;
            case 201:
              this.$root.snackbar.warn('Created new account');
              break;
            default:
              this.$root.snackbar.warn(response.data);
          }
          this.$root.snackbar.info('Code sent to your email');
        })
        .catch((error) => {
          if (error.response) {
            switch (error.response.status) {
              case 400:
                this.$root.snackbar.warn('Invalid email');
                break;
              case 429:
                this.$root.snackbar.warn(error.response.data);
                break;
              default:
                this.$root.snackbar.warn(error.response?.data ? error.response.data : 'Wrong email or password!');
            }
            console.log(error.response);
          } else if (error.request) {
            this.$root.snackbar.warn(error.request);
            console.log(error.request);
          } else {
            this.$root.snackbar.warn(error.message);
          }
        }).then(() => {
          this.cooldownTicker();
        });
      return true;
    },
  },
  mounted() {
    this.cooldownTicker();
  },
};
</script>
